import React from "react";
import { Box, jsx } from "theme-ui";
import { Flex, Heading } from "./core";

/** @jsx jsx */

const Highlights = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.08)",
        borderRadius: "8px",
        px: [2, 0, null],
        py: [1, 0, null],
        width: ["100%", "max-content", null],
        maxWidth: ["100%", "calc(100vw - 48px)", null],
        ...sx,
      }}
      {...props}
    >
      <Flex
        positions={[["stretch", "flex-start"], ["flex-start", "stretch"], null]}
        direction={["column", "row", null]}
        sx={{
          margin: "auto",
          "> *:not(:first-of-type)": {
            borderLeftWidth: [0, "1px", null],
            borderTopWidth: ["1px", 0, null],
            borderLeftStyle: "solid",
            borderTopStyle: "solid",
          },
        }}
      >
        {children}
      </Flex>
    </Box>
  );
};

const Item = ({ children, sx, ...props }) => (
  <Box
    sx={{
      textAlign: "center",
      borderColor: "#D5F1F2",
      px: [0, 4, 6],
      py: [2, 0, null],
      marginTop: [0, 3, null],
      marginBottom: [0, 4, null],
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
);

const Number = ({ children, variant = "h1" }) => (
  <Heading as="h3" variant={variant} color="primary">
    {children}
  </Heading>
);

const Label = ({ children, sx }) => (
  <Heading
    variant={["p1", "h4", null]}
    color="secondary"
    as="p"
    sx={{ fontWeight: "bold", ...sx }}
  >
    {children}
  </Heading>
);

Item.Number = Number;
Item.Label = Label;

Highlights.Item = Item;

export { Highlights };
